<template>
    <v-container fluid id="invoices_page">
        <img id="logo_img" :src="require('../../assets/receipt-header_short.png')" style="display:none"/>

        <v-row dense>
            <v-col cols="3" lg="3" md="3" sm="3">
                <v-menu :close-on-content-click="true" max-width="290">
                    <template v-slot:activator="{ on }">
                        <v-text-field name="date_from" type="text" class="custom_field"
                                      :value="$Helper.methods.formatDate(search.date_from)"
                                      :label="$t('general.date_from')" outlined readonly
                                      :disabled="loading" v-on="on">
                        </v-text-field>
                    </template>
                    <v-date-picker v-model="search.date_from" :locale="locale" no-title @input="getData">
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="3" lg="3" md="3" sm="3">
                <v-menu :close-on-content-click="true" max-width="290">
                    <template v-slot:activator="{ on }">
                        <v-text-field name="date_to" type="text" class="custom_field"
                                      :value="$Helper.methods.formatDate(search.date_to)"
                                      :label="$t('general.date_to')" outlined readonly
                                      :disabled="loading" v-on="on">
                        </v-text-field>
                    </template>
                    <v-date-picker v-model="search.date_to" :locale="locale" no-title @input="getData">
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="3" lg="3" md="3" sm="3">
                <v-btn color="primary" :disabled="loading" @click="clearSearchForm">
                    {{$t('general.clear')}}
                </v-btn>
            </v-col>
            <v-col cols="3" lg="3" md="3" sm="3">
                <download-excel
                        class="btn btn-default"
                        :data="items"
                        :fields="json_fields"
                        worksheet="My Worksheet"
                        name="filename.xls">
                    <v-btn color="primary">
                        Download Excel
                    </v-btn>
                </download-excel>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" lg="12">
                <!--Datatable Toolbar-->
                <v-toolbar flat color="white" elevation="1" class="custom_datatable_toolbar" height="56">
                    <v-toolbar-title>{{$t('invoices.list')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn v-if="canCreateItem" color="green" dark class="custom_button mobile_button"
                           :title="$t('clients.create')" to="/create_invoice">
                        <v-icon small size="20">add</v-icon>
                    </v-btn>

                </v-toolbar>

                <!--Datatable-->
                <ResponsiveDataTable :headers="headers" :items="items" :totalItems="total_items" :loading="loading"
                                     :mobileBreakpoint="960" :itemsPerPage="20" :sortByColumn="1"
                                     :itemsPerPageOptions="[10, 50, 100, 200, 500]" @options-changed="optionsChanged">
                    <!--Buttons-->
                    <template v-slot:buttons="{item}">
                        <div class="actions">
                            <!--<v-icon v-if="canViewItem" color="black" :title="$t('clients.view')"-->
                            <!--@click="openItemDialog('view', item.item.id)">view_list-->
                            <!--</v-icon>-->
                            <v-icon v-if="showReceiptNumberButton
                            (item.item)" color="black" :title="$t('invoices.create_receipt')"
                                    @click="updateReceiptNumber(item.item.id);">
                                mdi-receipt-text-check-outline
                            </v-icon>
                            <v-icon v-if="$Helper.methods.canUserDoAction(canUpdateItem, isSuperAdmin, item.item)"
                                    color="primary" :title="$t('general.update')"
                                    @click="editInvoice(item.item.id)">
                                edit
                            </v-icon>
                            <v-icon v-if="$Helper.methods.canUserDoAction(canPrintItem, isSuperAdmin, item.item)"
                                    color="primary" :title="$t('general.update')"
                                    @click="printInvoice(item.item)">
                                print
                            </v-icon>
                        </div>
                    </template>
                </ResponsiveDataTable>
            </v-col>
        </v-row>
        <v-overlay :opacity="0.9" :value="overlay">
            <v-progress-circular indeterminate size="128">
                Loading...
            </v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>
    import ResponsiveDataTable from '../../components/ResponsiveDataTable.vue'
    import Dialog from '../../components/Dialog.vue'
    import _ from 'lodash';
    import moment from 'moment';

    export default {
        components: {
            ResponsiveDataTable, Dialog
        },
        props: {
            item_id: {
                default: null
            }
        },
        data: self => ({

            url: 'invoices',

            // actions permissions
            canViewItem: false,
            canCreateItem: false,
            canUpdateItem: false,
            canDeleteItem: false,

            overlay: false,

            // search
            search: {
                date_from: moment().date(1).format('YYYY-MM-DD'),
                date_to: moment().format('YYYY-MM-DD'),
            },

            // datatable
            headers: [],
            total_items: null,
            items: [],
            loading: false,
            options: {
                page: null,
                itemsPerPage: null,
                sortBy: [],
                sortDesc: [],
                groupBy: [],
                groupDesc: [],
                multiSort: false,
                mustSort: false
            },

            // manage item
            show_item_dialog: false,
            item_dialog_header: null,
            item_dialog_loading: false,
            item_action: null,

            business_id: 1,

            // delete item
            show_delete_item_dialog: false,
            delete_item_dialog_loading: false,

            json_fields: {
                'Invoice No': 'invoice_no',
                'Date': 'date',
                'Client Name': 'client_name',
                'Subtotal ': 'subtotal',
                'VAT': 'total_vat',
                'Total': 'total'
            },
        }),
        computed: {
            locale() {
                return this.$i18n.locale;
            },
            isSuperAdmin() {
                return this.$store.state.user_details.roles.map(obj => obj.name).indexOf('superadmin') > -1;
            }
        },
        created() {
            this.headers = this.getHeaders();
        },
        async mounted() {
            this.canCreateItem = await this.$store.dispatch('hasPermission', 'create_invoice');
            this.canViewItem = await this.$store.dispatch('hasPermission', 'view_invoice');
            this.canUpdateItem = await this.$store.dispatch('hasPermission', 'update_invoice');
            this.canDeleteItem = await this.$store.dispatch('hasPermission', 'delete_invoice');
            this.canPrintItem = await this.$store.dispatch('hasPermission', 'print_invoice');
            this.setDefaultSearchCriteria();


        },
        methods: {
            // return headers array
            getHeaders() {
                return [
                    {text: `${this.$t('invoices.number')}`, value: 'invoice_no', sortable: true, width: '10%'},
                    {text: `${this.$t('invoices.receipt_number')}`, value: 'receipt_no', sortable: true, width: '10%'},
                    {text: `${this.$t('general.full_name')}`, value: 'client_name', sortable: true},
                    {text: `${this.$t('invoices.amount')}`, value: 'total', sortable: true},
                    {text: `${this.$t('general.date')}`, value: 'date', sortable: true},
                    {text: `${this.$t('datatable.actions')}`, value: 'actions', align: 'right', sortable: false}
                ]
            },
            // set default values for search form
            setDefaultSearchCriteria() {
                this.search.date_from = moment().date(1).format('YYYY-MM-DD');
                this.search.date_to = moment().format('YYYY-MM-DD');
            },
            // clear search form
            clearSearchForm() {
                this.setDefaultSearchCriteria();
                this.search_client = null;
                this.getData();
            },
            // datatable
            // apply changed options on datatable
            async optionsChanged(options) {
                let options_changed = false;
                this.loading = true;

                // check if options changed
                for (let prop in this.options) {
                    if (this.options[prop] !== options[prop]) {
                        options_changed = true;
                        break;
                    }
                }

                if (options_changed) {
                    this.options = Object.assign({}, options);
                    await this.getData();
                }
            },
            async getData() {
                this.loading = true;

                let search_criteria = {};
                search_criteria.date_from = `${this.search.date_from} 00:00:00`;
                search_criteria.date_to = `${this.search.date_to} 23:59:59`;

                let response = await  this.$store.dispatch('apiRequest', {
                    method: 'get', url: this.url, params: {
                        table_options: this.options, search_options: search_criteria
                    }
                });

                this.loading = false;

                // successful response
                if (!response.response) {
                    this.total_items = response.data.total;
                    this.items = response.data;
                    console.log('inside 14651 ' + this.item_id);

                    if (parseInt(this.item_id) !== 0 && this.item_id !== null) {
                        this.items.forEach((item) => {
                            console.log(item.id + ' ' + this.item_id);
                            if (parseInt(item.id) === parseInt(this.item_id)) {
                                this.$Helper.methods.printReceipt(item);
                            }
                        });
                    }

                }
            },
            // Trigger edit item action
            editInvoice(id) {
                this.$router.push(`/edit_invoice/${id}`);
            },
            printInvoice(item) {
                this.$Helper.methods.printReceipt(item);
            },
            async updateReceiptNumber(id) {
                let data = {
                    business_id: this.business_id
                };
                let response = await this.$store.dispatch('sendData', {
                    method: 'patch', url: `${this.url}/update_receipt_number/${id}`, data: data
                });

                if (!response.response) {
                    this.getData();
                }
            },
            showReceiptNumberButton(item) {
                if (item.receipt_no) {
                    return false;
                } else {
                    return true;
                }
            },
            onClientInput() {
                // when searching client start search after 3 chars typed
                if (this.search_client.length > 3) {
                    this.getData();
                }
            }
        }
    }
</script>