var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","id":"invoices_page"}},[_c('img',{staticStyle:{"display":"none"},attrs:{"id":"logo_img","src":require('../../assets/receipt-header_short.png')}}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"3","lg":"3","md":"3","sm":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"custom_field",attrs:{"name":"date_from","type":"text","value":_vm.$Helper.methods.formatDate(_vm.search.date_from),"label":_vm.$t('general.date_from'),"outlined":"","readonly":"","disabled":_vm.loading}},on))]}}])},[_c('v-date-picker',{attrs:{"locale":_vm.locale,"no-title":""},on:{"input":_vm.getData},model:{value:(_vm.search.date_from),callback:function ($$v) {_vm.$set(_vm.search, "date_from", $$v)},expression:"search.date_from"}})],1)],1),_c('v-col',{attrs:{"cols":"3","lg":"3","md":"3","sm":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"custom_field",attrs:{"name":"date_to","type":"text","value":_vm.$Helper.methods.formatDate(_vm.search.date_to),"label":_vm.$t('general.date_to'),"outlined":"","readonly":"","disabled":_vm.loading}},on))]}}])},[_c('v-date-picker',{attrs:{"locale":_vm.locale,"no-title":""},on:{"input":_vm.getData},model:{value:(_vm.search.date_to),callback:function ($$v) {_vm.$set(_vm.search, "date_to", $$v)},expression:"search.date_to"}})],1)],1),_c('v-col',{attrs:{"cols":"3","lg":"3","md":"3","sm":"3"}},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.loading},on:{"click":_vm.clearSearchForm}},[_vm._v(" "+_vm._s(_vm.$t('general.clear'))+" ")])],1),_c('v-col',{attrs:{"cols":"3","lg":"3","md":"3","sm":"3"}},[_c('download-excel',{staticClass:"btn btn-default",attrs:{"data":_vm.items,"fields":_vm.json_fields,"worksheet":"My Worksheet","name":"filename.xls"}},[_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" Download Excel ")])],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-toolbar',{staticClass:"custom_datatable_toolbar",attrs:{"flat":"","color":"white","elevation":"1","height":"56"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('invoices.list')))]),_c('v-spacer'),(_vm.canCreateItem)?_c('v-btn',{staticClass:"custom_button mobile_button",attrs:{"color":"green","dark":"","title":_vm.$t('clients.create'),"to":"/create_invoice"}},[_c('v-icon',{attrs:{"small":"","size":"20"}},[_vm._v("add")])],1):_vm._e()],1),_c('ResponsiveDataTable',{attrs:{"headers":_vm.headers,"items":_vm.items,"totalItems":_vm.total_items,"loading":_vm.loading,"mobileBreakpoint":960,"itemsPerPage":20,"sortByColumn":1,"itemsPerPageOptions":[10, 50, 100, 200, 500]},on:{"options-changed":_vm.optionsChanged},scopedSlots:_vm._u([{key:"buttons",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions"},[(_vm.showReceiptNumberButton
                        (item.item))?_c('v-icon',{attrs:{"color":"black","title":_vm.$t('invoices.create_receipt')},on:{"click":function($event){return _vm.updateReceiptNumber(item.item.id);}}},[_vm._v(" mdi-receipt-text-check-outline ")]):_vm._e(),(_vm.$Helper.methods.canUserDoAction(_vm.canUpdateItem, _vm.isSuperAdmin, item.item))?_c('v-icon',{attrs:{"color":"primary","title":_vm.$t('general.update')},on:{"click":function($event){return _vm.editInvoice(item.item.id)}}},[_vm._v(" edit ")]):_vm._e(),(_vm.$Helper.methods.canUserDoAction(_vm.canPrintItem, _vm.isSuperAdmin, item.item))?_c('v-icon',{attrs:{"color":"primary","title":_vm.$t('general.update')},on:{"click":function($event){return _vm.printInvoice(item.item)}}},[_vm._v(" print ")]):_vm._e()],1)]}}])})],1)],1),_c('v-overlay',{attrs:{"opacity":0.9,"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"128"}},[_vm._v(" Loading... ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }